import React from 'react';
import Image from 'reusecore/src/elements/Image';
import DocumentationSection from '../../../../../containers/SaasModern/Documentation';
import example_agenda from '../../../../../../../common/src/assets/image/example_agenda.png';
import template_options_agenda from '../../../../../../../common/src/assets/image/template_options_agenda.png';

const Documentation = () => {
  const content = (
    <div>
      <p>
        The Agenda template style generates an agenda-style listing of events or
        tasks for the specified date range.
      </p>
      <p>
        <Image alt="" src={example_agenda} style={{ width: 750 }} />
      </p>
      <h2>Template Settings</h2>
      <p>
        The Agenda template provides the following options when creating a
        calendar...
      </p>
      <Image alt="" src={template_options_agenda} style={{ width: 451 }} />
      <br />
      <table className="table">
        <tbody>
          <tr>
            <td>
              <strong>Start date</strong>
            </td>
            <td>The first date to include in the output.</td>
          </tr>
          <tr>
            <td>
              <strong>Number of days</strong>
            </td>
            <td>The total number of days to include in the output.</td>
          </tr>
          <tr>
            <td>
              <strong>Include items on</strong>
            </td>
            <td>
              If set to <strong>All days</strong>, items occurring on both
              weekends and weekdays will be included in the output. To limit the
              output to only show items that occur on weekends, select{' '}
              <strong>Weekends only</strong>. Likewise, to limit the output to
              only show items that occur on weekdays, select{' '}
              <strong>Weekdays only</strong>.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Time Format</strong>
            </td>
            <td>
              The time format. Supported formats are <strong>12-hour</strong>{' '}
              (8:00 PM), <strong>24-hour</strong> (20:00),{' '}
              <strong>Shortened</strong> (8pm), <strong>Military </strong>
              (2000), and <strong>Numeric </strong>(20.0). An example time is
              shown for each format in the drop-down list.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Color item titles</strong>
            </td>
            <td>
              When checked, event and task titles will be colored based on the associated calendar data source or category color.{' '}
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show calendar names</strong>
            </td>
            <td>
              When checked, associated calendar names will be shown below task and event titles.{' '}
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show locations</strong>
            </td>
            <td>
              When checked, event or task locations will be included in the
              agenda.{' '}
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show descriptions</strong>
            </td>
            <td>
              When checked, event or task descriptions will be included in the
              agenda.{' '}
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show blank line between items</strong>
            </td>
            <td>
              When checked, blank lines will be inserted between items to help
              differentiate them.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/Create-Calendar/Templates-and-Options/Agenda"
      commentsId="commentsplus_post_133_489"
      title="Agenda Template"
      description="The Agenda template style generates a printable agenda-style listing of events or tasks for a specified date range. The data can be imported from Outlook, Google Calendar, and more."
      keywords="agenda template, printable agenda, printable calendar, 2020 agenda, print agenda, import agenda, Excel agenda, Word agenda, create agenda"
      content={content}
    />
  );
};

export default Documentation;
